<template>
  <section class="land-own-land">
    <section class="hero-small">
      <div>
        <h1>
          Je possède déjà un terrain à bâtir,<br>
          puis-je utiliser le configurateur Alysia ?
        </h1>
      </div>
    </section>
    <div class="grid-container">
      <div class="intro">
        <h6>
          Oui bien sûr. Vous avez déjà acquis ou bien êtes en passe d’acquérir votre terrain ? Bonne nouvelle, vous avez franchi une étape essentielle dans la réalisation de votre projet de construction.
          <br><br>
          Votre objectif est maintenant de trouver le bon modèle de maison. Celui qui devra s’adapter parfaitement à votre terrain mais aussi à votre mode de vie.
        </h6>
      </div>

      <card-type-img
        title="Grâce à notre incroyable configurateur, trouvez la maison parfaitement accordée à votre terrain">
        <template v-slot:content>
          <p>Notre configurateur vous permet de saisir les caratéristiques essentielles de votre terrain telle que la <strong>commune et la largeur du terrain en façade</strong>.</p>

          <p>Automatiquement, notre système intelligent <strong>sélectionne les modèles de maisons compatibles</strong> et, si nécessaire, les <strong>adaptent en largeur</strong>.</p>

          <p><strong>En moins de 2 minutes</strong>, vous obtenez votre <strong>sélection personnalisé</strong>e. Il ne vous reste plus qu’à explorer et configurer votre version préférée.</p>
        </template>
        <template v-slot:img>
          <img src="@/assets/img/pages/land-config-screen.jpg" alt="Configuration de maison" class="land-config-screen-img"/>
        </template>
      </card-type-img>

      <div class="card-find-house">
        <figure>
          <img src="@/assets/img/pages/find-house-background.png" alt="Maisons Alysia">
        </figure>
        <div>
          <h2>Trouvez la maison adaptée à votre terrain</h2>
          <router-link :to="{ name: 'home', hash: '#hero' }" class="link-button"><app-button theme="primary" hasArrow>Commencer</app-button></router-link>
          <router-link :to="{ name: 'configurator' }">En savoir plus</router-link>
        </div>
      </div>

      <div class="card-content">
        <h2>Gagnez du temps en obtenant instantanément le chiffrage complet de votre projet de construction.</h2>
        <p>
          Notre configurateur vous permet même renseigner <strong>le prix d’achat de votre terrain</strong> (sous compromis) et <strong>ses caractérisques techniques</strong> (raccordements, nature des sols…). <strong>Il calcule automatiquement votre budget global et vous propose une mensualité personnalisée intégrant votre terrain à la simulation financière</strong> : rapide car sans rendez-vous avec un commercial, transparent car pas de frais cachés.
        </p>
        <div class="cards">
          <div class="grid-x">
            <div class="cell large-auto">
              <div class="card-icon">
                <img src="@/assets/img/pages/budget-icon.png" alt="Mensualités" />
                <p>Budget tout compris</p>
              </div>
            </div>
            <div class="cell large-auto">
              <div class="card-icon">
                <img src="@/assets/img/pages/mensualites-icon.png" alt="Mensualités" />
                <p>Mensualités personnalisées</p>
              </div>
            </div>
            <div class="cell large-auto">
              <div class="card-icon">
                <img src="@/assets/img/pages/notice-icon.png" alt="Mensualités" />
                <p>Descriptif à télécharger</p>
              </div>
            </div>
          </div>
        </div>

        <p class="highlight">
          Nous privilégions les <router-link :to="{ name: 'house-in-allotment' }">terrains en lotissement</router-link> mais si vous possèdez un terrain isolé ou en diffus, votre constructeur Maisons Alysia validera sa viabilité lors de la visite terrain (nature des sols, raccordement, <router-link :to="{ name: 'flat-or-sloping-ground' }">terrain plat ou en pente</router-link>, <router-link :to="{ name: 'limit-his-land' }">terrain borné</router-link>…).
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'land-own-land',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
  .land-own-land
    background: $medium-bg
    .hero-small
      @include hero-small
      background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/land-own-hero.jpg)

    .intro
      margin: 6rem auto 5rem
      padding: 0 1rem
      max-width: 925px
      text-align: center

    .card-type-img
      margin: 3rem auto
      p ~ p
        margin-top: 1.5rem
      .img
        margin-right: 3.5rem

        @media (max-width: 768px)
          margin-right: 0

      img.land-config-screen-img
        object-fit: contain

    .card-find-house
      @include card-find-house
      margin: 3rem auto

    .card-content
      @include card-content
      display: flex
      align-items: center
      text-align: center
      margin: 1rem 0 7rem
      padding: 4.5rem 1rem
      background: linear-gradient(white 0, white 60%, $primary 60%, $primary 100%)
      h2
        margin-bottom: 2rem
        max-width: 770px
      p
        max-width: 925px
        &.highlight
          color: white
          font-size: 18px
          line-height: 29px
          a
            font-size: 18px
            text-decoration: none
            color: white
            line-height: 29px
            font-weight: 700
      @media (max-width: 768px)
        padding: 0
        background: white
        overflow: hidden
        > h2, > p, > .cards
          padding-left: 1rem
          padding-right: 1rem
        > h2
          margin-top: 4.5rem
          margin-bottom: 4rem
        p.highlight, .cards
          background: $primary
        .cards
          margin: 4rem 0 0 0
          padding: 0 1rem
          .cell:first-child
            position: relative
            z-index: 1
            .card-icon
              position: relative
              z-index: 1
            &::after
              content: ''
              display: block
              position: absolute
              top: 0
              left: -1rem
              width: 100vw
              height: 50%
              background: white
              z-index: 0
        p.highlight
          padding: 2rem 1rem 4rem 1rem

    .cards
      margin: 4rem 0

    .card-icon
      @include card-icon
      margin: 0 1rem

</style>
